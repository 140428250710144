const feedbackData = [
  {
    id: 1,
    name: "Lorem Ipsum",
    title: "Internal Implementation Officer",
    testimonial: "Testimonial",
    feedback:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    // "rating": "★★★★★",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
  },
  {
    id: 2,
    name: "John Doe",
    title: "Customer Web Consultant",
    testimonial: "Testimonial",
    feedback:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    rating: "★★★★★",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
  },
  {
    id: 3,
    name: "Jane Smith",
    title: "Lead Integration Producer",
    testimonial: "Testimonial",
    feedback: "When an unknown printer took a galley of type and scrambled it.",
    rating: "★★★★★",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
  },
  {
    id: 4,
    name: "Lorem Ipsum",
    title: "Internal Implementation Officer",
    testimonial: "Testimonial",
    feedback:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    rating: "★★★★★",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
  },
  {
    id: 5,
    name: "Lorem Ipsum",
    title: "Internal Implementation Officer",
    testimonial: "Testimonial",
    feedback:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    rating: "★★★★★",
    image:
      "http://themes.audemedia.com/html/goodgrowth/images/testimonial3.jpg",
  },
];

export default feedbackData;
