const data = [
    {
        'id': 1,
        'title': 'Team One Site Vartaknagar Won Ranjeet Chashak – 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared'
    },
    {
        'id': 2,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared'
    },
    {
        'id': 3,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg  ',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared'
    },
    {
        'id': 4,
        'title': 'Aditya Sports Padgha Team Won Padgha Gramin Samrat Chashak',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared'
    },
    {
        'id': 5,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared'
    },
    {
        'id': 6,
        'title': 'Team Navapada Won Nilje Makarsankrant Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared'
    },
    {
        'id': 7,
        'title': 'Ganesh XI Mahul Won Rishaan Champions Trophy 2016',
        'thumbnail': 'https://my.ispl-t10.com/images/videos/thumbnail/G6xqZDcENbrDJvfQtijojv9RGQUBa1C2JrOcA5jq.jpg',
        'date': '20 Mar 2024',
        'url': 'https://youtu.be/7ucE8BAychI?feature=shared'
    }
]

export default data;