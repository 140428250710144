const sliderData =[
    {
      "id": 1,
      "team1": "Majhi Mumbai",
      "team2": "Tiigers Of Kolkata",
      "logo1": "/images/logo1.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
      "winMsg" : "abc acb bdbkjcdsbcdskj kjsdbcv,msdc asd cads,m",
    },
    {
      "id": 2,
      "team1": "Majhi Mumbai",
      "team2": "Tiigers Of Kolkata",
      "logo1": "/images/logo1.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live",
       "winMsg" : "abc acb bdbkjcdsbcdskj kjsdbcv,msdc asd cads,m",
      "city" : "Kolkata"
    },
    {
      "id": 3,
      "team1": "Majhi Mumbai",
      "team2": "Tiigers Of Kolkata",
      "logo1": "/images/logo1.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live"
    },
    {
      "id": 4,
      "team1": "Majhi Mumbai",
      "team2": "Tiigers Of Kolkata",
      "logo1": "/images/logo1.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live"
    },
    {
      "id": 5,
      "team1": "Majhi Mumbai",
      "team2": "Tiigers Of Kolkata",
      "logo1": "/images/logo1.png",
      "logo2": "/images/kolkataTiger1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live"
    },
    {
      "id": 6,
      "team1": "Majhi Mumbai",
      "team2": "Tiigers Of Kolkata",
      "logo1": "/images/logo1.png",
      "logo2": "src/images/kolkataTiger 1.png",
      "score1": "58/9",
      "overs1": "10.0 OV",
      "score2": "60/0",
      "overs2": "7.4 OV",
      "stadium": "Dadoji Kondadev Stadium",
      "liveStatus": "Live"
    }
]
export default sliderData;