import React from "react";
import '../sectionTitleText/sectionTitle.scss'

const SectionTitle = () => {
  return (
  <section>
    {/* <div className="section-title">Best of Tenniscricket</div>
      <div className="section-subtitle">
      Every cricket story holds a special place in our hearts and that’s why your cricket matters
      </div> */}
      <div className="titleText">
        <h6>Best of Tenniscricket</h6>
        <p>Every cricket story holds a special place in our hearts and that’s why your cricket matters</p>
      </div>
</section>
  )
};

export default SectionTitle;
